:root {
  --light: #f7f7f7;
  --light-accent: #89afba;
  --main-brand: #0f1f2f;
  --dark-accent: #baa475;
  --dark: #2d3338;
  --invert: #f39c12;
}

@media screen and (min-width: 500px) {
  /* HEM */
  .entry-page {
    min-height: 100%;
    height: 100vh;
  }

  .hero {
    background-image: url(/Images/uppfart2.jpeg);
  }

  .blacksmith-copy {
    background-image: url(/Images/lunch2-min.jpeg);
  }
}

@media screen and (min-width: 768px) {
  nav {
    height: 4rem;
  }

  .logo {
    margin-left: 0.5rem;
  }

  .m-menu {
    top: 4rem;
    width: 50%;
  }

  .m-menu li {
    font-size: 1.2rem;
    padding: 2rem;
  }

  .fa-bars {
    font-size: 2.5rem;
    padding: 0.8rem;
  }

  .entry-page {
    height: 100vh;
    overflow: hidden;
  }

  .logo img {
    height: 3.9rem;
  }

  .brand {
    font-size: 0.8rem;
  }

  h1 {
    text-align: left;
    font-size: 3rem;
  }

  /* HEM */

  .copy h2,
  .anlaggning-copy h2,
  .verksamhet-copy h2 {
    max-width: 45ch;
    font-size: 1.5rem;
  }

  .buttons {
    justify-content: left;
  }

  .stables {
    width: 70%;
    margin: 0 auto;
  }

  .stables li {
    font-size: 0.8rem;
  }

  .stables div {
    border-radius: 1rem;
  }

  .social {
    width: 500px;
    margin-bottom: 2rem;
  }

  /* ANLÄGGNING */

  /*  VERKSAMHET */

  .verksamheter {
    width: 70%;
    margin: 2rem auto;
  }

  .contact-form-amicus {
    width: 60%;
    margin: 2rem 20%;
  }

  /* KONTAKT */

  .contact-form {
    padding: 2rem 20%;
  }
}

@media screen and (min-width: 992px) {
  nav {
    position: fixed;
  }

  .entry-page {
    height: 100vh;
  }

  .social-links {
    width: 50%;
    margin: 0 auto;
  }

  .menu {
    display: none;
  }

  .d-menu {
    display: block;
    height: 100%;
  }

  #orange-link {
    padding: 0.3rem 1rem;
    color: var(--main-brand);
    background: var(--dark-accent);
    border-radius: 1rem;
    border-bottom: none;
  }

  #orange-link:hover {
    background-color: var(--invert);
  }

  /* HEM */

  .btn-read-more {
    display: none;
  }

  .btn-contact {
    margin-left: 0;
    padding: 1rem 2rem;
  }

  .btn-card {
    font-size: 0.7rem;
  }

  button:hover {
    transform: scale(1.05);
  }

  .hero {
    margin-top: 4rem;
  }

  .partners {
    padding: 4rem 2.5rem;
    height: 100%;
  }

  .stables {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .bx,
  .nova,
  .blacksmith,
  .social {
    width: 45%;
    margin: 0;
  }

  /* .social {
    display: none;
  } */

  .social h2 {
    display: none;
  }

  /* ANLÄGGNING */

  .anlaggning-copy {
    margin-top: 4rem;
  }

  .anlaggning-photos {
    display: none;
  }

  .anlaggning-photos-flex {
    display: block;
  }

  .anlaggning-row {
    display: flex;
    width: 100%;
    background-color: var(--light);
  }

  .anlaggning-row img {
    min-width: 33%;
    max-width: 50%;
  }

  .hitta-hit {
    width: 70%;
    margin: 0 auto;
  }

  /*  VERKSAMHETER */

  .verksamhet-copy {
    margin-top: 4rem;
  }

  .verksamheter {
    width: 100%;
    margin: 0;
    gap: 2rem;
  }

  .motto h2 {
    text-align: left;
  }

  .long-card {
    width: 70%;
    margin: 0 auto;
  }

  .nova.long-card {
    background-position: center;
  }

  /* STALL AMICUS */

  .blacksmith-copy {
    margin-top: 4rem;
    height: 100%;
  }

  .blacksmith-photos {
    flex-direction: row;
    /* background: var(--main-brand); */
    margin: 2rem 0rem;
  }

  .blacksmith-photos img {
    min-width: 20%;
    max-width: 33%;
    height: auto;
  }

  .contact-form-amicus {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }

  /* KONTAKT */
  .contact-form {
    padding-top: 6.5rem;
  }
}

/*  IPAD PRO */

@media screen and (min-width: 1024px) and (min-height: 1000px) {
  h1 {
    font-size: 4rem;
  }

  .copy h2,
  .anlaggning-copy h2,
  .verksamhet-copy h2,
  .blacksmith-copy h2 {
    font-size: 2rem;
  }

  .stables li {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1200px) {
  nav {
    border-bottom: 0.1rem solid var(--dark-accent);
  }

  .d-menu ul {
    gap: 1rem;
  }

  .entry-page {
    min-height: 100%;
    height: 100vh;
    padding-bottom: 2rem;
  }

  h1 {
    margin-top: 0;
    font-size: 2.5rem;
  }

  /* HEM */

  .hero,
  .anlaggning-copy,
  .verksamhet-copy,
  .blacksmith-copy {
    background-position: 95% 50%;
    background-size: 40%;
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0);
  }

  .copy {
    width: 50%;
  }

  .copy h2 {
    font-size: 1.3rem;
  }

  /* ANLÄGGNING */

  .hitta-hit {
    width: 50%;
  }

  /* VERKSAMHETER */

  .anlaggning-copy h2,
  .verksamhet-copy h2,
  .blacksmith-copy h2 {
    font-size: 1.3rem;
    width: 45%;
  }

  .motto {
    width: 50%;
    margin: 2rem auto;
  }

  .long-card {
    width: 50%;
  }

  /* KONTAKT */

  .contact-form {
    padding-left: 30%;
    padding-right: 30%;
  }

  /* STALL AMICUS */
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 1.8rem;
  }

  .d-menu ul {
    font-size: 1rem;
  }
}
